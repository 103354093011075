import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'custom-translations',
    template: ''
})
export class CustomTranslationsComponent {
    private customTranslations: Array<string>;

    constructor(private translate: TranslateService) {
        this.customTranslations = [
            // Invoice types
            this.translate.instant('INVOICE'),
            this.translate.instant('DELIVERY'),
            this.translate.instant('CREDIT'),
            this.translate.instant('STORNO'),

            // Pagination
            this.translate.instant('paginator.items_per_page'),
            this.translate.instant('paginator.next_page'),
            this.translate.instant('paginator.previous_page'),
            this.translate.instant('paginator.first_page'),
            this.translate.instant('paginator.last_page'),
        ];
    }
}
