import {Injectable} from '@angular/core';
import {IPersistenceContainer, PersistenceService, StorageType} from "angular-persistence";
import {Storage} from "@ionic/storage";

@Injectable()
export class StorageContainer implements IPersistenceContainer {
    static PREFIX = 'ANGULAR_PERSISTENCE_STORAGE::kc::';

    private container: IPersistenceContainer;


    constructor(persistenceService: PersistenceService) {
        this.container = persistenceService.createContainer('kc', {type: StorageType.LOCAL});
    }

    set(key: string, value: any): boolean {
        return this.container.set(key, value);
    }

    get(key: string): any {
        return this.container.get(key);
    }

    remove(key: string): any {
        return this.container.remove(key);
    }

    removeAll(): void {
        this.container.removeAll();
    }

}
