import {Pipe, PipeTransform} from '@angular/core';
/*
 * Get keys array from object
 *
 * Example:
 *   {{ object | keys }}
 */
@Pipe({name: 'keys'})

export class ObjectKeysPipe implements PipeTransform {
    transform(value, args: string[]): any {
        let keys = [];
        for (let key in value) {
            keys.push(key);
        }
        return keys;
    }
}