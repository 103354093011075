import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, NG_VALIDATORS, ReactiveFormsModule} from "@angular/forms";
import {DynamicFormsCoreModule} from "@ng-dynamic-forms/core";
import {FormComponent} from "./form.component";
import {ModelNameDirective} from "./directives/model-name.directive";
import {AfterModelNameDirective} from "./directives/after-model-name.directive";
import {serverValidator} from "./validators/server-validator";
import {DynamicFormsMaterialUIModule} from "@ng-dynamic-forms/ui-material";
import {MatButtonModule, MatChipsModule, MatIconModule} from "@angular/material";


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DynamicFormsCoreModule.forRoot(),
        DynamicFormsMaterialUIModule,
        MatButtonModule,
        MatIconModule,
        MatChipsModule,
    ],
    exports: [
        CommonModule,
        TranslateModule,
        FormComponent,
        ModelNameDirective,
        AfterModelNameDirective
    ],
    declarations: [
        FormComponent,
        ModelNameDirective,
        AfterModelNameDirective
    ],
    providers: [
        {provide: NG_VALIDATORS, useValue: serverValidator, multi: true}
    ],
})
export class FormModule {
}
