import {InjectionToken} from "@angular/core";
import {IAppConfig} from "./app-config.interface";

export const APP_DI_CONFIG: IAppConfig = {
    API_DOMAIN: 'https://hq.kreher.gmbh/',
    API_ADMIN_URL: 'https://hq.kreher.gmbh/admin/api/v1/',
    RESPONSE_INVALID_TOKEN_STATTUS_CODE: 401007,
    RESPONSE_EXPIRED_TOKEN_STATTUS_CODE: 401008,
    DEFAULT_LOCALE: 'de',
};

export let APP_CONFIG = new InjectionToken<IAppConfig>('app.config');