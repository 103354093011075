import {Injectable, Injector} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient, HttpHeaders, HttpRequest} from "@angular/common/http";
import {ApiRouterService} from "./api-router.service";
import {Observable} from "rxjs/Rx";
import {Http} from "@angular/http";
import {Router} from "@angular/router";
import {APP_DI_CONFIG} from "../../app-config/app-config.constants";
import {LanguageHelper} from "../helpers/language-helper";
import {StorageContainer} from "../storage/storage-container";

@Injectable()
export class AuthService {

    static LOCAL_STORAGE_USER_KEY = 'current_user';
    static LOCAL_STORAGE_JWT_TOKEN_KEY = 'id_token';
    static LOCAL_STORAGE_LOGGED_KEY = 'is_logged_in';
    static LOCAL_STORAGE_REFRESH_JWT_TOKEN_KEY = 'refresh_token';

    headers: Object = {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept-Language': LanguageHelper.getCurrentLocale() || APP_DI_CONFIG.DEFAULT_LOCALE,
    };

    constructor(private http: Http,
                private storageContainer: StorageContainer,
                public jwtHelper: JwtHelperService) {
    }

    getHeaders(): HttpHeaders | any {
        return this.headers;
    }

    getAuthHeaders(request: HttpRequest<any> = null): HttpHeaders | any {
        let headers: any = this.headers;

        for (let headerName in headers) {
            if (request && request.headers.getAll(headerName)) {
                headers[headerName] = request.headers.getAll(headerName);
            }
        }

        headers['Authorization'] = 'Bearer ' + this.getToken();
        return headers;
    }

    public logout() {
        this.storageContainer.remove(AuthService.LOCAL_STORAGE_USER_KEY);
        this.storageContainer.remove(AuthService.LOCAL_STORAGE_LOGGED_KEY);
        this.storageContainer.remove(AuthService.LOCAL_STORAGE_JWT_TOKEN_KEY);
        this.storageContainer.remove(AuthService.LOCAL_STORAGE_REFRESH_JWT_TOKEN_KEY);
        location.href = '/login';
    }

    public login(data: any) {
        console.log(data)
        this.setTokens(data['token'], data['refresh_token'])
        this.storageContainer.set(AuthService.LOCAL_STORAGE_LOGGED_KEY, true);
        location.href = '';
    }

    public setTokens(token, refreshToken) {
        this.storageContainer.set(AuthService.LOCAL_STORAGE_JWT_TOKEN_KEY, token);
        this.storageContainer.set(AuthService.LOCAL_STORAGE_REFRESH_JWT_TOKEN_KEY, refreshToken);
    }

    public getToken(): string {
        return this.storageContainer.get(AuthService.LOCAL_STORAGE_JWT_TOKEN_KEY);
    }

    public static getTokenStatic(): string {
        return localStorage.getItem(StorageContainer.PREFIX + AuthService.LOCAL_STORAGE_JWT_TOKEN_KEY);
    }

    public getRefreshToken(): string {
        return this.storageContainer.get(AuthService.LOCAL_STORAGE_REFRESH_JWT_TOKEN_KEY);
    }

    public getRefreshedAuthHeaders(request: HttpRequest<any> = null): Observable<any> {
        if (!this.jwtHelper.isTokenExpired(this.getToken())) {
            return new Observable(observer => {
                observer.next(this.getAuthHeaders())
            });
        }

        return new Observable(observer => {
            this.http.post(ApiRouterService.getAdminRoute('refreshToken'),
                {
                    'refresh_token': this.getRefreshToken()
                }, {
                    headers: this.getHeaders()
                }
            ).map(res => res.json()).subscribe((data) => {
                this.setTokens(data['token'], data['refresh_token'])
                observer.next(this.getAuthHeaders(request))
            }, (error) => {
                this.logout();
            });
        });
    }

    public isAuthenticated(): boolean {
        const token = this.getToken();
        return token && !this.jwtHelper.isTokenExpired(token);
    }
}