import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'backward-navigation',
    templateUrl: './backward-navigation.component.html',
    styleUrls: ['./backward-navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BackwardNavigationComponent {
    @Input() linkText: string;
    @Input() linkURL: string;
}
