import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DynamicFormControlModel, DynamicFormService} from "@ng-dynamic-forms/core";
import {FormGroup} from "@angular/forms";
import {CrudService} from "../services/crud.service";
import {routerTransition} from "../../../../router.animations";
import {FormBuilder} from "../../../modules/form/form-builder";
import {ServerValidation} from "../../../modules/form/validators/server-validation";
import {SpinnerAnimationService} from "../../spinner/spinner-animation.service";
import {serverValidator} from "../../../modules/form/validators/server-validator";

@Component({
    selector: 'app-crud-update',
    template: '',
    encapsulation: ViewEncapsulation.None,
    animations: [routerTransition()],
    providers: [
        DynamicFormService
    ]
})

export class CrudUpdateComponent implements OnInit {

    /**
     * Model id
     */
    public id: number;
    public successMessage = '';
    public formModel: DynamicFormControlModel[] = [];
    public formGroup: FormGroup;

    constructor(public route: ActivatedRoute,
                public  _crudService: CrudService,
                public _spinnerService: SpinnerAnimationService,
                public  formService: DynamicFormService) {
    }

    ngOnInit() {
        this._spinnerService.runSpinner();
        this.initRouteParams();
        this.initDataModel();
    }

    initRouteParams() {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
        });
    }

    initDataModel() {
        throw new Error("Implement this method to get model data and initialize form");
    }

    initForm(model: any = []) {
        this._crudService.getUpdateFormConfigurations().subscribe(
            (data) => {
                let formConfigurations = data['data'].configurations;
                this.formModel = (new FormBuilder(formConfigurations, model)).build();
                this.formGroup = this.formService.createFormGroup(this.formModel);
                this._spinnerService.stopSpinner();
            }
        );
    }


    validate(currentControl: DynamicFormControlModel, options: UpdateOptions = null) {
        options = options || {route: {params: {id: this.id}, data: this.formGroup.value}};
        if (!('serverValidator' in currentControl['validators']) || this.formGroup.get(currentControl.name).invalid) {
            return;
        }

        this._crudService.validateOnUpdate(options).subscribe(
            (data) => {
                if (options.callbacks) {
                    if (options.callbacks.success) {
                        options.callbacks.success();
                    }
                }
            },
            (error) => {
                ServerValidation.validateControl(error.errors, currentControl.name, this.formModel, this.formGroup);
                if (options.callbacks) {
                    if (options.callbacks.error) {
                        options.callbacks.error();
                    }
                }
            },
        );
    }


    update(options: UpdateOptions = null) {
        options = options || {route: {params: {id: this.id}, data: this.formGroup.value}};

        this._crudService.update(options).subscribe(
            (data) => {
                this.successMessage = data.message;
                if (options.callbacks) {
                    if (options.callbacks.success) {
                        options.callbacks.success();
                    }
                }
            },
            (errors) => {
                let errorsResponse = JSON.parse(errors._body);
                ServerValidation.validateControls(errorsResponse.errors, this.formModel, this.formGroup);

                if (options.callbacks) {
                    if (options.callbacks.error) {
                        options.callbacks.error();
                    }
                }
            }
        );
    }

}