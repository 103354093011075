import {FormBuilder} from "../form-builder";
import {serverValidator} from "./server-validator";

export class ValidatorsBuilder {
    private fieldValidators: any = [];
    private validators: any = [];
    private asyncValidators: any = [];
    private validatorErrorMessage: any = [];
    private formBuilder: FormBuilder;

    constructor(validators: any, formBuilder: FormBuilder) {
        this.fieldValidators = validators;
        this.formBuilder = formBuilder;
    }

    build() {
        for (let validator in this.fieldValidators) {
            let statusCode = this.fieldValidators[validator]['statusCode'] || '';

            switch (validator) {
                case 'required':
                    this.validators['required'] = null;
                    this.validatorErrorMessage['required'] = this.formBuilder.errorMessages[statusCode];
                    break;
                case 'minLength':
                    this.validators['minLength'] = this.fieldValidators[validator].value;
                    this.validatorErrorMessage['minLength'] = this.formBuilder.errorMessages[statusCode];
                    break;
                case 'maxLength':
                    this.validators['maxLength'] = this.fieldValidators[validator].value;
                    this.validatorErrorMessage['maxLength'] = this.formBuilder.errorMessages[statusCode];
                    break;
                case 'email':
                    this.validators['email'] = null;
                    this.validatorErrorMessage['email'] = this.formBuilder.errorMessages[this.fieldValidators[validator]['statusCodes'][0]];
                    break;
                case 'serverSide':
                    this.validators['serverValidator'] = null;
                    break
            }

        }
        return this;
    }

    getAsyncValidators() {
        return this.asyncValidators;
    }

    getValidators() {
        return this.validators;
    }

    getErrorMessages() {
        return this.validatorErrorMessage;
    }

}