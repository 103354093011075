import {Injectable, Output, EventEmitter} from '@angular/core'

@Injectable()
export class SidebarOpenEventService {
    @Output() openedEvent: EventEmitter<any> = new EventEmitter();
    private changeOpenedEventTriggers: boolean = false;

    open() {
        this.changeOpenedEventTriggers = true;
        this.openedEvent.emit(this.changeOpenedEventTriggers);
    }

    close() {
        this.changeOpenedEventTriggers = false;
        this.openedEvent.emit(this.changeOpenedEventTriggers);
    }

    isChangedEventTriggers() {
        return this.changeOpenedEventTriggers;
    }

    stopChangedEvent() {
        this.changeOpenedEventTriggers = false;
    }

}
