import {Injectable} from '@angular/core';
import {APP_CONFIG, APP_DI_CONFIG} from "../../app-config/app-config.constants";
import {IAppConfig} from "../../app-config/app-config.interface";

@Injectable()
export class ApiRouterService {

    /**
     * Api routes collection
     */
    private static API_ROUTES: any = {
        userLogin: 'login',
        usersList: 'users',
        refreshToken: 'token/refresh',

        // Departments routs
        departmentsList: 'departments',
        getDepartment: 'departments/:id',
        departmentCreateFormConfigurations: 'departments/configurations/create',
        departmentUpdateFormConfigurations: 'departments/configurations/update',
        validateOnUpdateDepartment: 'departments/:id/validate',
        validateDepartment: 'departments/validate',
        createDepartment: 'departments',
        updateDepartment: 'departments/:id',

        // Order routes
        getOrderItems: 'orders/:id/order-items',
        orderDownloadMass: 'orders/zip',

        // Order items routs
        orderItemsSearch: 'order-items',
        getOrderItem: 'order-items/:id',
        getOrderItemShippingLabels: 'shipping-labels/order-items/:id',
        getOrderItemPrintingFiles: 'order-items/:id/printing-files',
        getOrderDocuments: 'orders/:id/documents',
        downloadShippingLabelFile: 'shipping-labels/:id/download',
        downloadDocumentPdf: 'documents/:id/download',
        downloadPrintingFile: 'printing-files/:token/download',
        downloadAllPrintingFiles: 'order-items/:id/printing-files/download',

        // Order item comments
        getOrderItemComments: 'orderitem/:orderItemId/comments',
        validateOrderItemComment: 'orderitem/:orderItemId/comments/validate',
        createOrderItemComment: 'orderitem/:orderItemId/comments',
        orderItemCommentCreateFormConfigurations: 'orderitem/:orderItemId/comments/configurations/create',
        orderItemCommentDelete: 'orderitem/:orderItemId/comments/:id',

        // Order sources
        orderSourcesList: 'order-sources',
        validateOrderSource: 'order-sources/validate',
        createOrderSource: 'order-sources',
        getOrderSource: 'order-sources/:id',
        orderSourceDelete: 'order-sources/:id',
        updateOrderSource: 'order-sources/:id',
        validateUpdateOrderSource: 'order-sources/:id/validate',
        orderSourceCreateFormConfigurations: 'order-sources/configurations/create',
        orderSourceUpdateFormConfigurations: 'order-sources/configurations/update',

        // Managers routs
        managersList: 'managers',
        validateUser: 'managers/validate',
        validateUpdateUser: 'managers/:id/validate',
        createUser: 'managers',
        updateUser: 'managers/:id',
        getManagerUser: 'managers/:id',
        userManagerCreateFormConfigurations: 'managers/configurations/create',
        userManagerUpdateFormConfigurations: 'managers/configurations/update',

        // Customers routs
        customersList: 'customers',
        validateCustomerUser: 'customers/validate',
        validateCustomerUserOnUpdate: 'customers/:id/validate',
        createCustomerUser: 'customers',
        getCustomerUser: 'customers/:id',
        updateCustomerUser: 'customers/:id',
        userCustomerCreateFormConfigurations: 'customers/configurations/create',
        userCustomerUpdateFormConfigurations: 'customers/configurations/update',
        createCustomerUserApiClientToken: 'customers/:id/api-client-token',
        uploadPublicKey: 'customers/:id/public-key',

        // Countries routs
        countriesList: 'countries',
        validateCountry: 'countries/validate',
        createCountry: 'countries',
        getCountry: 'countries/:id',
        updateCountry: 'countries/:id',
        validateUpdateCountry: 'countries/:id/validate',
        countryCreateFormConfigurations: 'countries/configurations/create',
        countryUpdateFormConfigurations: 'countries/configurations/update',

        // Packings routs
        packingsList: 'packings',
        packingsListForCountry: 'packings/countries/:countryId',
        packingsCreateImage: 'packings/image',
        createPacking: 'packings',
        validatePacking: 'packings/validate',
        validateUpdatePacking: 'packings/:id/validate',
        updatePacking: 'packings/:id',
        getPacking: 'packings/:id',
        packingCreateFormConfigurations: 'packings/configurations/create',
        packingUpdateFormConfigurations: 'packings/configurations/update',

        // Country packings
        countryPackingsList: 'countries/:countryId/packings',
        createCountryPacking: 'countries/:countryId/packings',
        validateCountryPacking: 'countries/:countryId/packings/validate',
        validateUpdateCountryPacking: 'countries/:countryId/packings/:id/validate',
        updateCountryPacking: 'countries/:countryId/packings/:id',
        getCountryPacking: 'countries/:countryId/packings/:id',
        countryPackingDelete: 'countries/:countryId/packings/:id',
        countryPackingCreateFormConfigurations: 'countries/:countryId/packings/configurations/create',
        countryPackingUpdateFormConfigurations: 'countries/:countryId/packings/configurations/update',

        // Carriers routs
        carriersList: 'carriers',
        carriersCreateImage: 'carriers/image',
        createCarrier: 'carriers',
        validateCarrier: 'carriers/validate',
        validateUpdateCarrier: 'carriers/:id/validate',
        updateCarrier: 'carriers/:id',
        getCarrier: 'carriers/:id',
        carrierCreateFormConfigurations: 'carriers/configurations/create',
        carrierUpdateFormConfigurations: 'carriers/configurations/update',

        // Address types routs
        addressTypesList: 'address-types',
        createAddressType: 'address-types',
        validateAddressType: 'address-types/validate',
        validateUpdateAddressType: 'address-types/:id/validate',
        updateAddressType: 'address-types/:id',
        getAddressType: 'address-types/:id',
        addressTypeCreateFormConfigurations: 'address-types/configurations/create',
        addressTypeUpdateFormConfigurations: 'address-types/configurations/update',

        // Shipping types routs
        shippingTypesList: 'shipping-types',
        createShippingType: 'shipping-types',
        validateShippingType: 'shipping-types/validate',
        validateUpdateShippingType: 'shipping-types/:id/validate',
        updateShippingType: 'shipping-types/:id',
        updateShippingTypePosition: 'shipping-types/:id/position',
        getShippingType: 'shipping-types/:id',
        shippingTypeCreateFormConfigurations: 'shipping-types/configurations/create',
        shippingTypeUpdateFormConfigurations: 'shipping-types/configurations/update',
    };

    static getConfig(): IAppConfig {
        return APP_DI_CONFIG;
    }

    /**
     * Ger route by route name
     * @param name
     * @returns {any|string}
     */
    static getAdminRoute(name, params: any = {}) {
        let route: string = ApiRouterService.getConfig().API_ADMIN_URL + ApiRouterService.API_ROUTES[name] || '';

        // Replace params in route if params is not empty
        if (Object.keys(params).length) {
            for (let param in params) {
                route = route.replace(':' + param, params[param]);
            }
        }

        return route
    }

}