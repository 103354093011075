import {DynamicFormControlModel} from "@ng-dynamic-forms/core";
import {InputControl} from "./controls/input-control";
import {CheckboxControl} from "./controls/checkbox-control";
import {SelectControl} from "./controls/select-control";
import {SelectMultipleControl} from "./controls/select-multiple-control";
import {InputPasswordControl} from "./controls/input-password-control";
import {InputHiddenControl} from "./controls/input-hidden-control";
import {InputEmailControl} from "./controls/input-email-control";
import {TextareaControl} from "./controls/textarea-control";
import {InputNumberControl} from "./controls/input-number-control";

export class FormBuilder {
    fields: any;
    modelData: any;
    errorMessages: any;
    model: DynamicFormControlModel[] = [];

    constructor(configs, modelData: any = []) {
        this.fields = configs.fields;
        this.errorMessages = configs.statusCodes;
        this.modelData = modelData;
    }

    build() {
        for (let field of this.fields) {
            switch (field.type) {
                case 'input':
                    this.model.push((new InputControl(this, field)).build());
                    break;
                case 'checkbox':
                    this.model.push((new CheckboxControl(this, field)).build());
                    break;
                case 'select':
                    this.model.push((new SelectControl(this, field)).build());
                    break;
                case 'select-multiple':
                    this.model.push((new SelectMultipleControl(this, field)).build());
                    break;
                case 'password':
                    this.model.push((new InputPasswordControl(this, field)).build());
                    break;
                case 'hidden':
                    this.model.push((new InputHiddenControl(this, field)).build());
                    break;
                case 'email':
                    this.model.push((new InputEmailControl(this, field)).build());
                    break;
                case 'number':
                    this.model.push((new InputNumberControl(this, field)).build());
                    break;
                case 'textarea':
                    this.model.push((new TextareaControl(this, field)).build());
                    break;
            }
        }
        return this.model;
    }
}
