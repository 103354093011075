import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {ListTableComponent} from "./list-table.component";
import {RouterModule} from "@angular/router";
import {
    MatButtonModule, MatDividerModule,
    MatIconModule, MatPaginatorModule, MatSortModule,
    MatTableModule, MatTooltipModule
} from "@angular/material";
import {ControlNameDirective} from "./directives/control-name.directive";
import {DndModule, SortableComponent, SortableHandleComponent} from "ng2-dnd";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        DndModule.forRoot(),
        MatTooltipModule,
        MatPaginatorModule,
        MatDividerModule,
    ],
    exports: [
        CommonModule,
        TranslateModule,
        ListTableComponent,
        ControlNameDirective,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        MatSortModule,
        MatTooltipModule,
    ],
    declarations: [
        ListTableComponent,
        ControlNameDirective,
    ],
})
export class ListTableModule {
}
