import {Directive, TemplateRef, ViewContainerRef, Input} from '@angular/core';

@Directive({
    selector: '[controlName]'
})
export class ControlNameDirective {
    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
    }

    @Input() set controlName(name: string) {
        this.templateRef['controlName'] = name;
    }
}