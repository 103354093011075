import {StorageContainer} from "../storage/storage-container";
export class LanguageHelper {


    static LOCAL_STORAGE_KEY = StorageContainer.PREFIX + 'locale';
    static LOCALES: {} = {'en': 'en-US', 'de': 'de-DE',};

    public static setLocale(locale) {
        localStorage.setItem(LanguageHelper.LOCAL_STORAGE_KEY, locale);
    }

    public static getLocales() {
        return Object.keys(LanguageHelper.LOCALES);
    }

    public static setCurrentLocale(locale) {
        localStorage.setItem(LanguageHelper.LOCAL_STORAGE_KEY, locale);
    }

    public static getCurrentLocale() {
        return localStorage.getItem(LanguageHelper.LOCAL_STORAGE_KEY);
    }

    public static getCurrentLocaleId() {
        return LanguageHelper.LOCALES[LanguageHelper.getCurrentLocale()];
    }
}