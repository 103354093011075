import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AuthService} from "../services/auth.service";
import {APP_DI_CONFIG} from "../../app-config/app-config.constants";


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request.clone({
            setHeaders: this.auth.getAuthHeaders(request)
        })).catch((res) => {
            if ([
                    APP_DI_CONFIG.RESPONSE_EXPIRED_TOKEN_STATTUS_CODE,
                    APP_DI_CONFIG.RESPONSE_INVALID_TOKEN_STATTUS_CODE
                ].indexOf(res.error.statusCode) !== -1) {
                return this.auth.getRefreshedAuthHeaders(request).flatMap(headers => {
                    return next.handle(request.clone({
                        setHeaders: headers
                    }));
                });
            } else {
                return Observable.throw(res.error);
            }
        });
    }
}