import {Directive, TemplateRef, ViewContainerRef, Input} from '@angular/core';

@Directive({
    selector: '[afterModelName]'
})
export class AfterModelNameDirective {
    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
    }

    @Input() set afterModelName(name: string) {
        this.templateRef['afterModelName'] = name;
    }
}