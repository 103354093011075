import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {routerTransition} from "../../../../router.animations";
import {DynamicFormControlModel, DynamicFormService} from "@ng-dynamic-forms/core";
import {FormGroup} from "@angular/forms";
import {FormBuilder} from "../../../modules/form/form-builder";
import {ServerValidation} from "../../../modules/form/validators/server-validation";
import {CrudService} from "../services/crud.service";
import {SpinnerAnimationService} from "../../spinner/spinner-animation.service";
import {serverValidator} from "../../../modules/form/validators/server-validator";

@Component({
    selector: 'app-crud-create',
    template: '',
    encapsulation: ViewEncapsulation.None,
    animations: [routerTransition()],
    providers: [
        DynamicFormService
    ]
})

export class CrudCreateComponent implements OnInit {

    public successMessage = '';
    public formModel: DynamicFormControlModel[] = [];
    public formGroup: FormGroup;

    constructor(public _crudService: CrudService,
                public formService: DynamicFormService,
                public _spinnerService: SpinnerAnimationService,) {
    }

    ngOnInit() {
        this._spinnerService.runSpinner();
        this.initForm();
    }

    initForm(model: any = {}, options: any = {}) {
        this._crudService.getCreateFormConfigurations(options).subscribe(
            (data) => {
                let formConfigurations = data['data'].configurations;
                this.formModel = (new FormBuilder(formConfigurations, model)).build();
                this.formGroup = this.formService.createFormGroup(this.formModel);
                this._spinnerService.stopSpinner();
            }
        );
    }

    validate(currentControl: DynamicFormControlModel, options: CreateOptions = null) {
        options = options || {route: {data: this.formGroup.value}};

        if (!('serverValidator' in currentControl['validators']) || this.formGroup.get(currentControl.name).invalid) {
            return;
        }

        this._crudService.validateOnCreate(options)
            .subscribe(
                (data) => {
                    if (options.callbacks) {
                        if (options.callbacks.success) {
                            options.callbacks.success();
                        }
                    }
                },
                (error) => {
                    ServerValidation.validateControl(error.errors, currentControl.name, this.formModel, this.formGroup);
                    if (options.callbacks) {
                        if (options.callbacks.error) {
                            options.callbacks.error();
                        }
                    }
                },
            );
    }

    create(options: CreateOptions = null) {
        options = options || {route: {data: this.formGroup.value}};

        this._crudService.create(options).subscribe(
            (data) => {
                this.successMessage = data.message;
                if (options.callbacks) {
                    if (options.callbacks.success) {
                        options.callbacks.success();
                    }
                }
            },
            (errors) => {
                let errorsResponse = errors instanceof Object ? errors : JSON.parse(errors._body);
                ServerValidation.validateControls(errorsResponse.errors, this.formModel, this.formGroup);
                if (options.callbacks) {
                    if (options.callbacks.error) {
                        options.callbacks.error();
                    }
                }
            }
        );
    }

}