import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG} from "../../app-config/app-config.constants";
import {IAppConfig} from "../../app-config/app-config.interface";
import {User} from '../models/user';
import {ApiRouterService} from "./api-router.service";
import {AuthService} from "./auth.service";
import {Http} from "@angular/http";
import {CrudService} from "../components/crud/services/crud.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {StorageContainer} from "../storage/storage-container";

@Injectable()
export class UsersService extends CrudService {
    static USER_STORAGE_KEY = 'current_user';

    private currentUser: User;

    constructor(private authHttp: HttpClient,
                private http: Http,
                private storageContainer: StorageContainer,
                private authService: AuthService,
                @Inject(APP_CONFIG) private config: IAppConfig) {
        super();
    }


    getOne(id: number) {

    }

    create(options: CreateOptions) {
    }

    getCreateFormConfigurations(options: CreateOptions) {

    }

    validateOnCreate(options: CreateOptions) {

    }

    getAll() {
        return this.authHttp.get(ApiRouterService.getAdminRoute('usersList')).catch(err => {
            return Observable.of({data: {users: []}});
        });
    }

    login(email: string, password: string) {
        let body = JSON.stringify({"username": email, "password": password});

        let headers = this.authService.getHeaders();
        headers['Content-type'] = 'application/json';

        return this.http.post(ApiRouterService.getAdminRoute('userLogin'), body, {
            headers: headers,
        }).map(res => res.json());
    }

    /**
     * setUser
     */
    setCurrentUser(user: any) {
        this.currentUser = new User(user);
        this.storageContainer.set(UsersService.USER_STORAGE_KEY, this.currentUser);
    }

    getCurrentUser() {
        this.currentUser = this.storageContainer.get(UsersService.USER_STORAGE_KEY);
        return this.currentUser;
    }

    update(options: UpdateOptions) {
    };

    getUpdateFormConfigurations() {
    };

    validateOnUpdate(options: UpdateOptions) {
    };

    delete(options: DeleteOptions) {
    };
}