import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {BackwardNavigationComponent} from "./backward-navigation.component";
import {RouterModule} from "@angular/router";
import {MatIconModule, MatTooltipModule} from "@angular/material";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        MatTooltipModule,
        MatIconModule
    ],
    exports: [
        CommonModule,
        TranslateModule,
        BackwardNavigationComponent
    ],
    declarations: [
        BackwardNavigationComponent
    ],
})
export class BackwardNavigationModule {
}
