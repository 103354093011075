import {Injectable, Output, EventEmitter} from '@angular/core'

@Injectable()
export class ListsTableDataSourceChangeEventService {
    @Output() changedEvent: EventEmitter<any> = new EventEmitter();
    private changedEventTriggers: boolean = false;
    private dataSource = [];

    change(dataSource) {
        this.changedEventTriggers = true;
        this.changedEvent.emit(true);
        this.dataSource = dataSource;
    }

    getDataSource(){
        return this.dataSource;
    }

    isChangedEventTriggers() {
        return this.changedEventTriggers;
    }

    stopChangedEvent() {
        this.changedEventTriggers = false;
    }

}
