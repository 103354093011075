import {
    AfterContentInit, Component, ContentChildren, ElementRef, Input, OnInit, QueryList, TemplateRef, ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {MatTable, PageEvent} from "@angular/material";
import {ListsTableDataSourceChangeEventService} from "./services/list-table-data-source-change-events.service";
import {Pagination} from "./models/pagination";

const TABLE_DIRECTION_HORIZONTAL = 'horizontal';
const TABLE_DIRECTION_VERTICAL = 'vertical';

@Component({
    selector: 'list-table',
    templateUrl: './list-table.component.html',
    styleUrls: ['./list-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        ListsTableDataSourceChangeEventService,
    ]
})
export class ListTableComponent implements AfterContentInit, OnInit {
    @Input() dataSource;
    @Input() displayedColumns;
    @Input() paginationSource: Pagination;

    @Input() buttonAddNewItemTitle: string = '';
    @Input() buttonEditItemTitle: string = '';
    @Input() buttonDeleteItemTitle: string = '';
    @Input() tableDirection: string = TABLE_DIRECTION_HORIZONTAL;

    @Input() createNewItemCallback = null;
    @Input() editItemCallback = null;
    @Input() deleteItemCallback = null;
    @Input() sortingCallback = null;
    @Input() paginationChangeCallback = null;

    @ContentChildren('controlsTemplates')
    public controlsTemplatesList: QueryList<TemplateRef<any>>;
    public replacedControls: Array<Object> = [];

    public dragEnabled: boolean = false;
    public hasSorting: boolean = false;
    public hasPagination: boolean = false;

    constructor(private dataSourceChangeEventService: ListsTableDataSourceChangeEventService) {
    }

    ngOnInit() {
        this.displayedColumns['widthClasses'] = this.displayedColumns['widthClasses'] || [];
        this.subscribeOnChangeEvent();
    }

    ngAfterContentInit() {
        if (this.isVerticalDirection()) {
            this.adaptToVerticalDirection();
        }

        this.hasSorting = this.sortingCallback != null;
        this.hasPagination = this.paginationSource != null;
        this.initReplacedControls();
    }

    adaptToVerticalDirection() {
        let newDataSource = [];
        for (let columns of this.dataSource) {
            let properties = Object.getOwnPropertyNames(columns);

            for (let i in properties) {
                let columnName = properties[i];
                let title = this.displayedColumns['titles'][i];
                if (columns[columnName]) {
                    newDataSource.push({
                        title: title,
                        value: columns[columnName],
                        name: columnName,
                    });
                }
            }
        }
        this.dataSource = newDataSource;
        this.displayedColumns.names = ['item'];
        this.displayedColumns['titles'] = [];
    }

    subscribeOnChangeEvent() {
        this.dataSourceChangeEventService.changedEvent.subscribe(state => {
            if (state) {
                delete this.dataSource;
                this.dataSource = this.dataSourceChangeEventService.getDataSource();
                this.initReplacedControls();
                this.dataSource = this.dataSource.slice();
            }
        });
    }

    initReplacedControls() {
        this.controlsTemplatesList.forEach(item => {
            this.replacedControls.push({
                controlName: item['controlName'],
                ref: item
            });
        });
    }

    isInReplacedControls(name, element) {
        let controlName = 'control-' + name;
        if (this.isHorizontalDirection()) {
            controlName += '-' + element['id'];
        }

        for (let replacedControl of this.replacedControls) {
            if (replacedControl['controlName'] == controlName) {
                return true;
            }
        }

        return false;
    }

    getReplacedControlRef(name, element) {
        let controlName = 'control-' + name;
        if (this.isHorizontalDirection()) {
            controlName += '-' + element['id'];
        }

        for (let replacedControl of this.replacedControls) {
            if (replacedControl['controlName'] == controlName) {
                return replacedControl['ref'];
            }
        }
        return null
    }

    enableSorting() {
        this.dragEnabled = true;
    }

    disableSorting() {
        this.dragEnabled = false;
    }

    dropSuccess(item) {
        let index = null;
        for (let i in this.dataSource) {
            if (this.dataSource[i]['id'] === item['id']) {
                index = i;
            }
        }

        if (index === null) return;

        this.disableSorting();
        this.sortingCallback(index, item);
        this.dataSource = this.dataSource.slice();
    }

    isEditingControlTemplate(controlName, element) {
        return controlName == 'edit-button' + element['id'] && !element['isDeleted']
    }

    isDeletingControlTemplate(controlName, element) {
        return controlName == 'delete-button' + element['id'] && !element['isDeleted']
    }

    hasEditingButton(element) {
        return this.editItemCallback && !element['isDeleted'];
    }

    hasDeletingButton(element) {
        return this.deleteItemCallback && !element['isDeleted'];
    }

    hasDataModifier(columnName) {
        if (!this.displayedColumns.hasOwnProperty('dataModifiers')) return false;
        if (!this.displayedColumns['dataModifiers'].hasOwnProperty(columnName)) return false;
        return true;
    }

    modifyColumnData(columnName, element) {
        return this.displayedColumns['dataModifiers'][columnName](element);
    }

    isHorizontalDirection() {
        return this.tableDirection == TABLE_DIRECTION_HORIZONTAL;
    }

    isVerticalDirection() {
        return this.tableDirection == TABLE_DIRECTION_VERTICAL;
    }

    onPaginationChange(pageEvent: PageEvent) {
        this.paginationSource.perPage = pageEvent.pageSize;
        this.paginationSource.currentPage = pageEvent.pageIndex + 1;

        if (this.paginationChangeCallback) {
            this.paginationChangeCallback(pageEvent);
        }
    }
}
