export class Pagination {
    public total: number;
    public currentPage: number = 1;
    public lastPage: number;
    public perPage: number = 10;
    public pageSizeOptions: number[] = [5, 10, 25, 100];

    constructor(data: {} | null = null) {
        if (!data || !Object.keys(data).length) return;

        this.total = data['total'];
        this.currentPage = data['current_page'] || 1;
        this.lastPage = data['last_page'];
        this.perPage = data['per_page'] || 10;
        this.pageSizeOptions = data['page_size_options'] || [5, 10, 25, 100];
    }
}