import {
    DynamicCheckboxGroupModel, DynamicCheckboxModel, DynamicSelectModel
} from "@ng-dynamic-forms/core";
import {ValidatorsBuilder} from "../validators/validators-builder";
import {FormBuilder} from "../form-builder";
import {number} from "ng2-validation/dist/number";

export class SelectMultipleControl {
    field: any;
    formBuilder: FormBuilder;

    constructor(formBuilder: FormBuilder, field: any) {
        this.field = field;
        this.formBuilder = formBuilder;
    }

    build() {
        let validatorBuilder = (new ValidatorsBuilder(this.field.constraints, this.formBuilder)).build();
        let options = this.formBuilder.modelData[this.field.name] ? this.formBuilder.modelData[this.field.name]['all'] : [];
        let selected = this.formBuilder.modelData[this.field.name] ? this.formBuilder.modelData[this.field.name]['selected'] : [];

        let model = new DynamicSelectModel({
            id: this.field.name,
            label: this.field.label,
            options: options,
            filterable: true,
            multiple: true,
            validators: validatorBuilder.getValidators(),
            asyncValidators: validatorBuilder.getAsyncValidators(),
            errorMessages: validatorBuilder.getErrorMessages()
        });

        if (selected) {
            model.select.apply(model, selected);
        }
        return model;
    }
}
