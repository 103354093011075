import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ObjectKeysPipe} from "./object-keys.pipe";
import {HumanFileSizePipe} from "./human-file-size.pipe";
import {NumberFormatPipe} from "./number-format-pipe.pipe";

@NgModule({
    declarations: [
        ObjectKeysPipe,
        HumanFileSizePipe,
        NumberFormatPipe
    ],
    imports: [
        CommonModule
    ],
    exports:[
        ObjectKeysPipe,
        HumanFileSizePipe,
        NumberFormatPipe,
    ]
})
export class SharedPipesModule { }
