import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from "@angular/common";

@Pipe({name: 'numberFormat'})

export class NumberFormatPipe extends DecimalPipe implements PipeTransform {

    transform(number: number = 0, format: string ): string {
        return super.transform(number, format).replace(/,/g, '.').replace(/\.([^\.]+)$/, ',$1');
    }
}
