import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {EmptyListComponent} from "./empty-list.component";
import {RouterModule} from "@angular/router";
import {MatButtonModule, MatChipsModule, MatIconModule} from "@angular/material";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatChipsModule
    ],
    exports: [
        CommonModule,
        TranslateModule,
        EmptyListComponent
    ],
    declarations: [
        EmptyListComponent
    ],
})
export class EmptyListModule {
}
