import {Pipe, PipeTransform} from '@angular/core';
/*
 * Get keys array from object
 *
 * Example:
 *   {{ object | keys }}
 */
@Pipe({name: 'human_fileize'})

export class HumanFileSizePipe implements PipeTransform {

    static readonly kB = 1000;
    static readonly MB = Math.pow(10, 6);
    static readonly GB = Math.pow(10, 9);
    static readonly TB = Math.pow(10, 12);

    private units = [
        'bytes',
        'kB',
        'MB',
        'GB',
        'TB',
    ];

    transform(bytes: number = 0, precision: number = 2): string {
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

        let unit = 0;

        if (bytes / HumanFileSizePipe.kB < 1) {
            unit = 0;
        } else if (bytes / HumanFileSizePipe.kB >= 1 && bytes / HumanFileSizePipe.MB < 1) {
            unit = 1;
            precision = bytes % HumanFileSizePipe.kB ? precision : 0;
            bytes = bytes / HumanFileSizePipe.kB;
        } else if (bytes / HumanFileSizePipe.MB >= 1 && bytes / HumanFileSizePipe.MB < 100) {
            unit = 2;
            precision = bytes % HumanFileSizePipe.MB ? precision : 0;
            bytes = bytes / HumanFileSizePipe.MB;
        } else if (bytes / HumanFileSizePipe.MB >= 100 && bytes / HumanFileSizePipe.GB < 100) {
            unit = 3;
            precision = bytes % HumanFileSizePipe.GB ? precision : 0;
            bytes = bytes / HumanFileSizePipe.GB;
        } else if (bytes / HumanFileSizePipe.GB >= 100) {
            unit = 4;
            precision = bytes % HumanFileSizePipe.TB ? precision : 0;
            bytes = bytes / HumanFileSizePipe.TB;
        }

        return bytes.toFixed(precision).replace('.', ',') + ' ' + this.units[unit];
    }
}
