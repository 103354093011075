import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UsersService} from '../../services/users.service';
import {AuthService} from "../../services/auth.service";
import {LanguageHelper} from "../../helpers/language-helper";
import {MenuPositionX} from "@angular/material";
import {DropdownStateService} from "ng2-material-dropdown";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        UsersService,
        DropdownStateService
    ]
})
export class HeaderComponent implements OnInit {
    public locales: any = LanguageHelper.getLocales();
    public languages: {};

    constructor(private translate: TranslateService,
                public router: Router,
                public authService: AuthService,
                public dropService: DropdownStateService,
                private usersService: UsersService) {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd && window.innerWidth <= 992) {
                this.toggleSidebar();
            }
        });

        this.languages = {
            'en': this.translate.instant('English'),
            'de': this.translate.instant('Deutsch'),
        }

    }

    ngOnInit() {
    }

    isCurrentLocale(locale) {
        return LanguageHelper.getCurrentLocale() === locale;
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('push-right');
    }

    rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    onLoggedout() {
        this.authService.logout();
    }

    getCurrentUserName() {
        let user = this.usersService.getCurrentUser();
        return user ? user.firstName + ' ' + user.lastName : '';
    }

    changeLang(language: string) {
        LanguageHelper.setLocale(language);
        window.location.reload();
    }

}
