import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {APP_DI_CONFIG} from "./app-config/app-config.constants";
import {LanguageHelper} from "./shared/helpers/language-helper";
import {MatPaginatorIntlCustomService} from "./shared/components/paginator/mat-paginator-intl-custom.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {

    constructor(private translate: TranslateService) {
        this.setLocale();
    }

    setLocale() {
        this.translate.addLangs(['en', 'de']);
        this.translate.setDefaultLang(APP_DI_CONFIG.DEFAULT_LOCALE);
        const browserLang = this.translate.getBrowserLang();

        if (LanguageHelper.getCurrentLocale()) {
            this.translate.use(LanguageHelper.getCurrentLocale());
        } else {
            this.translate.use(browserLang.match(/en|de/) ? browserLang : APP_DI_CONFIG.DEFAULT_LOCALE);
            LanguageHelper.setCurrentLocale(this.translate.currentLang)
        }
    }
}
