import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'empty-list',
    templateUrl: './empty-list.component.html',
    styleUrls: ['./empty-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EmptyListComponent {
    @Input() createNewItemUrl: string;
    @Input() createNewItemButtonText: string;
    @Input() emptyBlockText: string;
}
