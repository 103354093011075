import {
    AfterContentInit,
    AfterViewInit,
    Component, ContentChild, ContentChildren, ElementRef, Input, OnInit, QueryList, TemplateRef, ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {FormService} from "./form.service";
import {FormGroup} from "@angular/forms";
import {DynamicFormControlComponent, DynamicFormControlModel, DynamicTemplateDirective} from "@ng-dynamic-forms/core";
import {Observable} from "rxjs/Observable";
import {forEach} from "@angular/router/src/utils/collection";


@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        FormService
    ]
})
export class FormComponent implements AfterContentInit {
    @Input() formGroup: FormGroup;
    @Input() formModel: DynamicFormControlModel[] = [];
    @Input() successMessage: string = '';
    @Input() buttonText: string = '';
    @Input() buttonWidthClass: string = 'col-3';
    @Input() validateCallback;
    @Input() createCallback;
    @Input() inputCallback = () => {
    };

    @ContentChildren('additionalControl')
    public additionalControlsList: QueryList<TemplateRef<any>>;
    public additionalControls: Array<Object> = [];

    @ContentChildren('additionalAfterForm')
    public additionalsAfterFormList: QueryList<TemplateRef<any>>;
    public additionalsAfterForm: Array<Object> = [];

    @ContentChildren('controlsTemplates')
    public controlsTemplatesList: QueryList<TemplateRef<any>>;
    public replacedControls: Array<Object> = [];

    constructor(public _formService: FormService) {
    }

    ngAfterContentInit() {
        this.initReplacedControls();
        this.initAdditionalControls();
        this.initAdditionalsAfterForm();
    }

    initReplacedControls() {
        this.controlsTemplatesList.forEach(item => {
            this.replacedControls.push({
                modelId: item['modelName'],
                ref: item
            });
        });
    }

    initAdditionalControls() {
        this.additionalControlsList.forEach(item => {
            this.additionalControls.push({
                afterModelName: item['afterModelName'],
                ref: item
            });
        });
    }

    initAdditionalsAfterForm() {
        this.additionalsAfterFormList.forEach(item => {
            this.additionalsAfterForm.push({
                ref: item
            });
        });
    }

}