export interface CrudServiceInterface {
    getOne(id: number);
    create(options: CreateOptions);
    getCreateFormConfigurations(options: CreateOptions);
    validateOnCreate(options: CreateOptions);
    update(options: UpdateOptions);
    getUpdateFormConfigurations();
    validateOnUpdate(options: UpdateOptions);
    delete(options: DeleteOptions);
}


export abstract class CrudService implements CrudServiceInterface {

    abstract getOne(id: number);

    abstract create(options: CreateOptions);
    abstract getCreateFormConfigurations(options: CreateOptions);
    abstract validateOnCreate(options: CreateOptions);

    abstract update(options: UpdateOptions);
    abstract getUpdateFormConfigurations();
    abstract validateOnUpdate(options: UpdateOptions);

    abstract delete(options: DeleteOptions);

    protected mapResponse(response: any) {
        return response.map(res => {
            // If request fails, throw an Error that will be caught
            if (res.status < 200 || res.status >= 300) {
                throw new Error('This request has failed ' + res.status);
            }
            // If everything went fine, return the response
            else {
                return res.json();
            }
        });
    }
}