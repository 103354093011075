import {Directive, TemplateRef, ViewContainerRef, Input} from '@angular/core';

@Directive({
    selector: '[modelName]'
})
export class ModelNameDirective {
    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
    }

    @Input() set modelName(name: string) {
        this.templateRef['modelName'] = name;
    }
}