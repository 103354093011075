export class MenuItem {
    title: string = '';
    url: string = null;
    icon: string = '';
    items: Array<MenuItem> = [];
    parent: MenuItem;
    active: boolean = false;


    constructor() {
    }

    setTitle(title: string) {
        this.title = title;
        return this;
    }

    setUrl(url: string) {
        this.url = url;
        return this;
    }

    setIcon(icon: string) {
        this.icon = icon;
        return this;
    }

    activate() {
        this.active = true;
    }

    deactivate() {
        this.active = false;
    }

    addItem(item: MenuItem) {
        item.parent = this;
        this.items.push(item);
        return this;
    }

}