import {MatPaginatorIntl} from '@angular/material';
import {TranslateService} from "@ngx-translate/core";
import {Injectable} from "@angular/core";

@Injectable()
export class MatPaginatorIntlCustomService extends MatPaginatorIntl{
    translate: TranslateService;
    /** A label for the page size selector. */
    itemsPerPageLabel: string;
    /** A label for the button that increments the current page. */
    nextPageLabel: string;
    /** A label for the button that decrements the current page. */
    previousPageLabel: string;
    /** A label for the button that moves to the first page. */
    firstPageLabel: string;
    /** A label for the button that moves to the last page. */
    lastPageLabel: string;

    injectTranslateService(translate: TranslateService) {
        this.translate = translate;

        this.translate.onLangChange.subscribe(() => {
            this.translateLabels();
        });

        this.translateLabels();
    }

    translateLabels() {
        this.itemsPerPageLabel = this.translate.instant('paginator.items_per_page');
        this.nextPageLabel = this.translate.instant('paginator.next_page');
        this.previousPageLabel = this.translate.instant('paginator.previous_page');
        this.firstPageLabel = this.translate.instant('paginator.first_page');
        this.lastPageLabel = this.translate.instant('paginator.last_page');
    }

}