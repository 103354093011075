export class User {
    id: number;
    enabled: boolean;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: any = [];
    departments: any = [];
    lastLogin: any;
    isDeleted: boolean;

    constructor(userData: any) {
        this.id = userData.id;
        this.username = userData.username;
        this.enabled = userData.enabled;
        this.lastLogin = userData.last_login ? new Date(userData.last_login) : '';
        this.firstName = userData.first_name;
        this.lastName = userData.last_name;
        this.email = userData.email;
        this.roles = userData.roles || [];
        this.departments = userData.departments || [];
        this.isDeleted = userData.deleted_at ? true : false;
    }

}