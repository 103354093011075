import {Component, ContentChild, ContentChildren, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from "./menu-item";
import {TranslateService} from "@ngx-translate/core";
import {SidebarOpenEventService} from "./services/sidebar-open-events.service";
import {StorageContainer} from "../../storage/storage-container";

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})

export class SidebarComponent implements OnInit {
    static LOCAL_STORAGE_KEY = 'sidebar_menu_active_item_url';

    public activeMenuItem: MenuItem;
    public menuItems: Array<MenuItem> = [
        new MenuItem()
            .setTitle(this.translate.instant('Dashboard'))
            .setUrl('/dashboard')
            .setIcon('dashboard'),

        new MenuItem()
            .setTitle(this.translate.instant('orders'))
            .setIcon('local_atm')
            .addItem(
                new MenuItem()
                    .setTitle(this.translate.instant('orderItems'))
                    .setUrl('/orders/order-items')
                    .setIcon('local_atm'),
            )
            .addItem(
                new MenuItem()
                    .setTitle(this.translate.instant('Mass Download'))
                    .setUrl('/orders/download')
                    .setIcon('archive'),
            ),
        new MenuItem()
            .setTitle(this.translate.instant('shop'))
            .setIcon('store')
            .addItem(
                new MenuItem()
                    .setTitle(this.translate.instant('countries'))
                    .setUrl('/shop/countries')
                    .setIcon('language'),
            )
            .addItem(
                new MenuItem()
                    .setTitle(this.translate.instant('packings'))
                    .setUrl('/shop/packings')
                    .setIcon('table_chart')
            )
            .addItem(
                new MenuItem()
                    .setTitle(this.translate.instant('addressTypes'))
                    .setUrl('/shop/address-types')
                    .setIcon('table_chart')
            )
            .addItem(
                new MenuItem()
                    .setTitle(this.translate.instant('shippingTypes'))
                    .setUrl('/shop/shipping-types')
                    .setIcon('table_chart')
            )
            .addItem(
                new MenuItem()
                    .setTitle(this.translate.instant('orderSources'))
                    .setUrl('/shop/order-sources')
                    .setIcon('table_chart'),
            )
            .addItem(
                new MenuItem()
                    .setTitle(this.translate.instant('carriers'))
                    .setUrl('/shop/carriers')
                    .setIcon('table_chart')
            ),
        new MenuItem()
            .setTitle(this.translate.instant('management'))
            .setIcon('people')
            .addItem(
                new MenuItem()
                    .setTitle(this.translate.instant('managers'))
                    .setUrl('/management/managers')
                    .setIcon('people'),
            )
            .addItem(
                new MenuItem()
                    .setTitle(this.translate.instant('customers'))
                    .setUrl('/management/customers')
                    .setIcon('people'),
            )
            .addItem(
                new MenuItem()
                    .setTitle(this.translate.instant('departments'))
                    .setUrl('/management/departments')
                    .setIcon('people'),
            ),
    ];

    constructor(private translate: TranslateService,
                private storageContainer: StorageContainer,
                private sidebarOpenEventService: SidebarOpenEventService) {
    }

    ngOnInit() {
        this.activateItemByUrl(this.getSavedActiveItemUrl(), this.menuItems);
    }

    activateItemByUrl(url: string, items: Array<MenuItem>) {
        if (!url) return;

        for (let item of items) {
            item.deactivate();

            if (item.url == url) {
                if (item.parent) {
                    item.parent.activate();
                }

                item.activate();
                this.activeMenuItem = item;
                continue;
            }

            if (item.items) {
                this.activateItemByUrl(url, item.items);
            }
        }
    }

    activateItem(menuItem: MenuItem, drawerSecondLevel) {
        if (this.activeMenuItem) {
            if (this.activeMenuItem.parent) {
                this.activeMenuItem.parent.deactivate();
            }

            if (!this.activeMenuItem.parent) {
                this.activeMenuItem.deactivate();
            }
        }

        this.sidebarOpenEventService.open();
        menuItem.activate();
        this.activeMenuItem = menuItem;
        drawerSecondLevel.open();
        this.saveActiveItemToStorage(menuItem);
    }

    isActiveItem(menuItem: MenuItem) {
        return menuItem.title == this.activeMenuItem.title;
    }

    saveActiveItemToStorage(menuItem: MenuItem) {
        if (!menuItem.url) return;
        this.storageContainer.set(SidebarComponent.LOCAL_STORAGE_KEY, menuItem.url)
    }

    saveActiveItem(menuItem: MenuItem) {
        this.saveActiveItemToStorage(menuItem);
        this.activateItemByUrl(this.getSavedActiveItemUrl(), this.menuItems);
    }

    getSavedActiveItemUrl() {
        let firstMenuItem: MenuItem = this.menuItems[0];
        return this.storageContainer.get(SidebarComponent.LOCAL_STORAGE_KEY) || firstMenuItem.url;
    }

    hideSecondLevelSidebar(drawerSecondLevel) {
        drawerSecondLevel.toggle();
        this.sidebarOpenEventService.close();
    }
}
