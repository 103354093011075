import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class SpinnerAnimationService {

    spinnerEvent: EventEmitter<any> = new EventEmitter();
    isStopped: boolean = false;

    runSpinner(): void {
        this.isStopped = false;
        this.spinnerEvent.emit(true);
    }

    stopSpinner(): void {
        this.spinnerEvent.emit(false);
        setTimeout(function () {
            this.isStopped = true;
        }.bind(this), 200)
    }

    getSpinnerEvent(): any {
        return this.spinnerEvent;
    }

}