import {NgModule, Injector, LOCALE_ID, ElementRef} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Http, HttpModule} from '@angular/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthGuard} from './shared';
import {APP_CONFIG, APP_DI_CONFIG} from "./app-config/app-config.constants";
import {AuthService} from "./shared/services/auth.service";
import {SharedPipesModule} from "./shared/pipes/shared-pipes.module";
import {DynamicFormsCoreModule} from "@ng-dynamic-forms/core";
import {DynamicFormsNGBootstrapUIModule} from "@ng-dynamic-forms/ui-ng-bootstrap";
import {DynamicFormsPrimeNGUIModule} from "@ng-dynamic-forms/ui-primeng";
import {SpinnerAnimationService} from "./shared/components/spinner/spinner-animation.service";
import {FormModule} from "./shared/modules/form/form.module";
import {BackwardNavigationModule} from "./shared/modules/backward-navigation/backward-navigation.module";
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from "@angular/common/http";
import {TokenInterceptor} from "./shared/interceptors/token.interceptor";
import {EmptyListModule} from "./shared/modules/empty-list/empty-list.module";
import {ModalModule} from "ng2-bs4-modal/lib/ng2-bs4-modal.module";
import {CrudCreateComponent} from "./shared/components/crud/create/crud-create.component";
import {CrudUpdateComponent} from "./shared/components/crud/update/crud-update.component";
import {NumberValidator} from "ng2-validation/dist/number";
import * as moment from 'moment';
import {CustomTranslationsComponent} from "./shared/components/custom-translations/custom-translations.component";
import 'hammerjs';
import 'mousetrap';
import {ModalGalleryModule} from 'angular-modal-gallery';
import {NgSelectModule} from "@ng-select/ng-select";
import {EditorModule} from "@tinymce/tinymce-angular";
import {tinymceDefaultSettings, TinyMceModule} from "angular-tinymce";
import {JwtModule, JWT_OPTIONS} from '@auth0/angular-jwt';
import {IonicStorageModule, Storage} from '@ionic/storage';
import {LanguageHelper} from "./shared/helpers/language-helper";
import {CdkColumnDef} from "@angular/cdk/table";
import {
    MatButtonModule, MatCell, MatHeaderCell, MatIconModule, MatPaginatorIntl, MatTableModule,
    MatToolbarModule
} from "@angular/material";
import {SortableComponent} from "ng2-dnd";
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import {registerLocaleData} from "@angular/common";
import {PersistenceModule} from "angular-persistence";
import {StorageContainer} from "./shared/storage/storage-container";
import {MatPaginatorIntlCustomService} from "./shared/components/paginator/mat-paginator-intl-custom.service";

registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        CrudCreateComponent,
        CrudUpdateComponent,
        NumberValidator,
        CustomTranslationsComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgSelectModule,
        FormsModule,
        HttpModule,
        AppRoutingModule,
        SharedPipesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        DynamicFormsCoreModule.forRoot(),
        DynamicFormsNGBootstrapUIModule,
        DynamicFormsPrimeNGUIModule,
        FormModule,
        BackwardNavigationModule,
        EmptyListModule,
        HttpClientModule,
        ModalModule,
        ModalGalleryModule.forRoot(),
        EditorModule,
        TinyMceModule.forRoot(tinymceDefaultSettings()),
        IonicStorageModule.forRoot(),
        JwtModule.forRoot({
            config: {
                tokenGetter: () => {
                    return AuthService.getTokenStatic();
                }
            }
        }),
        MatIconModule,
        MatToolbarModule,
        PersistenceModule
    ],
    providers: [
        {
            provide: APP_CONFIG,
            useValue: APP_DI_CONFIG,
        },
        AuthGuard,
        StorageContainer,
        SpinnerAnimationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        AuthService,
        {
            provide: 'moment',
            useValue: moment
        },
        {
            provide: LOCALE_ID,
            useValue: LanguageHelper.getCurrentLocaleId()
        },
        CdkColumnDef,
        MatHeaderCell,
        MatCell,
        {
            provide: MatPaginatorIntl,
            useFactory: (translate) => {
                const service = new MatPaginatorIntlCustomService();
                service.injectTranslateService(translate);
                return service;
            },
            deps: [TranslateService]

        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
